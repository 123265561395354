<template>
  <div class="content">
    <header-nav></header-nav>
    <div class="top-part">
      <div class="top-b">
        <div class="my-img">
          <img :src="require('@/assets/images/lanm1.png')" alt="" />
        </div>
        <div class="big-t">蓝目训练器(blueye)</div>
      </div>
      <div class="but-box">
        <!-- <el-button @click="downLoado" type="primary">点击下载</el-button> -->
        <el-button @click="downLoadBd" type="primary" target="_blank">百度网盘下载</el-button>
      </div>
      <div class="lit-t">仅限windows操作系统电脑版（手机版会后续推出）</div>
      <div class="line"></div>
      <div class="mind-t lineleft">
        蓝目训练器的完整安装，需要如下几个部分：
      </div>
      <div class="lit-t lineleft">
        <p>1，主应用程序：包含了行情、算法、回测、自动下单等功能；</p>
        <p>
          2，python环境（2.7以上），主要用于算法测试。<a class="linkpart" href="https://www.anaconda.com/distribution/">去www.anaconda.com下载</a>
        </p>
        <p>* 下载anaconda 的python3.7的32位，目前暂不支持64位</p>
      </div>
      <div class="lit-t lineleft">
        如上2个部分，第1个部分是必须的；第2个部分python环境，如果未安装，则不能使用python的相关算法库。
      </div>
      <div class="lit-t lineleft">
        <a class="linkpart"
          href="https://www.lanmuda.com.cn/upload/%E8%93%9D%E7%9B%AE%E8%AE%AD%E7%BB%83%E5%99%A8%E5%88%9D%E7%BA%A7%E4%BD%BF%E7%94%A8%E6%95%99%E7%A8%8B.pdf">教程下载</a>
      </div>
    </div>
    <div class="top-b">
      <div class="my-img">
        <img :src="require('@/assets/images/lanm1.png')" alt="" />
      </div>
      <div class="big-t">蓝目训练器(blueye)</div>
    </div>
    <div class="but-box">
      <!-- <el-button @click="downLoadt" type="primary">点击下载</el-button> -->
      <el-button @click="downLoadBd" type="primary">百度网盘下载</el-button>
    </div>
    <div class="lit-t">仅限windows操作系统电脑版（手机版会后续推出）</div>
    <div class="line"></div>
    <!-- <div class="top-b">
      <div class="big-t">python环境支持</div>
    </div>
    <div class="but-box">
      <el-button @click="downLoadth" type="primary">安装说明</el-button>
    </div>
    <div class="line"></div> -->
    <div class="top-b">
      <div class="big-t">使用手册</div>
    </div>
    <div class="but-box">
      <el-button @click="downLoadf" type="primary">点击下载</el-button>
    </div>
    <div class="line"></div>
    <div class="top-b">
      <div class="big-t">升级程序</div>
    </div>
    <div class="but-box">
      <el-button @click="downLoadfi" type="primary">点击下载</el-button>
    </div>
    <div class="lit-t">仅限windows操作系统电脑版（手机版会后续推出）</div>
    <floor></floor>
  </div>
</template>

<script>
import HeaderNav from "@/components/Header.vue";

import Floor from "@/components/Floor.vue";

export default {
  components: {
    HeaderNav,

    Floor,
  },
  methods: {
    downLoadBd () {
      window.open("https://pan.baidu.com/s/15rDUNSlCnK_8XjPrQEtWjA?pwd=5566")
      //   window.location.href = "https://pan.baidu.com/s/1YXz9WKa8mZ8wsn5Csu_-iw?pwd=5566";
    },
    downLoado () {
      window.location.href = "https://www.lanmuda.com.cn/upload/blueye_setup.exe";
    },
    downLoadt () {
      window.location.href = "https://www.lanmuda.com.cn/upload/blueye_setup.exe";
    },
    downLoadth () {
      window.location.href = "https://www.lanmuda.com.cn/blueye2/python_install.jsp?is_only_body=1";
    },
    downLoadf () {
      window.location.href = "https://www.lanmuda.com.cn/upload/help.pdf";
    },
    downLoadfi () {
      window.location.href = "https://www.lanmuda.com.cn/upload/upgrade.rar";
    },
  },
};
</script>

<style lang="less" scoped>
.top-part {
  margin: 50px auto;
  width: 100%;
  max-width: 1150px;
  padding: 50px 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.top-b {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 0 10px;
}

.my-img {
  width: 40px;
  height: 40px;

  img {
    width: 100%;
  }
}

.but-box {
  padding: 20px;
}

.big-t {
  padding-left: 8px;
  font-size: 28px;
  line-height: 28px;
}

.lit-t {
  font-size: 14px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.8);
}

.line {
  width: 100%;
  max-width: 700px;
  // text-align: center;
  margin: 15px auto;

  border: 1px dashed rgba(0, 0, 0, 0.5);
}

.mind-t {
  font-size: 24px;
  padding: 10px 0;
}

.lineleft {
  text-align: left;
  margin: 0 auto;
  max-width: 700px;
  width: 100%;
}

.linkpart {
  text-decoration: none;
  color: #337ab7;
}
</style>